<template>
  <div style="display:flex">
    <div class="left-body">
      <div class="pr-1">
        <v-select
          v-model="bookingDetailId"
          label="FullName"
          :options="listBookingDetail"
          :reduce="x => x.Id"
          :clearable="true"
          @input="EmailDetail(bookingDetailId)"
          placeholder="Tìm kiếm tên, BBC"
        />
        <div style="overflow-y: auto; height: 760px;">
          <div>
            <b-button
              class="print"
              variant="primary"
              @click="EmailGroup()"
              style="margin-top: 10px"
              >Email đoàn</b-button
            >
          </div>
          <div v-for="(items, index) in listBookingDetail" :key="index">
          <b-button
            class="print"
            variant="primary"
            style="margin-top: 10px"
           @click="EmailDetail(items.Id)" 
          >
            <div style="padding: 5px 0 5px 0">
                {{ items.BookingDetailCode }} - {{ items.FullName }}
              </div>
          </b-button>
        </div>
        </div>
      </div>
    </div>
    <div class="right-body"> 
      <div class="top-content">
        <v-select
        style="width:120px"
        v-model="LangId"
        label="LangCode"
        :options="listLanguages"
        :reduce="x => x.LangId"
        :clearable="true"
        placeholder="Ngôn ngữ"
        />
      <b-button
        variant="primary"
        style="margin-left: 20px"
        @click="exportPDF"
        >{{ $t("golf_common_export_to_pdf") }}</b-button
      >
      <b-button 
        variant="primary" 
        style="margin-left: 20px"
        @click="openSidebarSendEmail"
      >
        {{ $t("golf_confirm_email_booking") }}
      </b-button
      >
    </div>
    <div v-if="type == 'BOOKED_GROUP'" style="display: flex">
      <VueHtml2pdf
        :show-layout="true"
        :enable-download="true"
        :manual-pagination="true"
        ref="html2Pdf"
        filename="ConfirmBooking"
      >
        <section slot="pdf-content">
          <div v-html="keyValue"></div>
        </section>
      </VueHtml2pdf>
    </div>
    <div v-if="type == 'BOOKED'" style="display: flex">
      <VueHtml2pdf
        :show-layout="true"
        :enable-download="true"
        :manual-pagination="true"
        ref="html2Pdf"
        filename="ConfirmBooking"
      >
        <section slot="pdf-content">
          <div v-html="keyValue"></div>
        </section>
      </VueHtml2pdf>
    </div>
    <div v-if="type == 'CANCEL'" style="display: flex; justify-content: center">
      <VueHtml2pdf
        :show-layout="true"
        :enable-download="true"
        :manual-pagination="true"
        ref="html2Pdf"
        filename="CancelBooking"
      >
        <section slot="pdf-content">
          <div v-html="keyValue"></div>
        </section>
      </VueHtml2pdf>
    </div>
    <SendEmail
      ref="SendEmail"
      :data="dataBooking" 
      :bodyEmail="htmlEmail"
      :type="type"
    />
    </div>
  </div>
</template>
<script>
import { commonServices } from "@/api/common-services";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { booking } from "@/api/booking";
import vSelect from 'vue-select'
import SendEmail from '@/views/modules/booking/Email/SendEmail.vue'
export default {
  name: "confirmEmail",
  components: {
    SendEmail
  },
  data() {
    return {
      filename: 'abc',
      bookingDetailId: null,
      keyValue: null,
      show: true,
      BookingDetail: null,
      Booking: null,
      BookingDetailCourse: null,
      type: null,
      htmlEmail: null,
      BookingCode: null,
      BookingCal: {
        BookingId: null,
        BookingCode: null,
        OpenDate: [],
        TeeTime: [],
        TotalNumberOfGolfer: 0,
        TotalPrice: 0,
      },
      listBookingDetail: [],
      getListBookingRBK02A: null,
      htmlAll: null,
      dataBooking: null,
      listLanguages: [],
      LangId: 1000000,
      HtmlAllEng: null,
      HtmlAllVie: null,
    };
  },
  created() {
    html2canvas.useCORS = true;
    this.api_RBK02A();
    this.getListLanguages()
    this.call_api_RCOM01_getHtmlEng()
  },
  watch: {
    LangId() {
      if(this.LangId == 1000000) {
        this.htmlAll = this.HtmlAllVie
      } else {
        this.htmlAll = this.HtmlAllEng
      }
      this.replaceEmail()
    }
  },
  methods: {
    async api_RBK02A () {
      this.BookingCode = this.$route.params.BookingCode
      const body = {
        KeyWord: "",
        BookingCode: this.BookingCode,
        BookingDetail: null,
        BookingId: null,
        BDC: [],
      }
      await booking.getDetailBooking2A(body).then(res => {
        this.BookingCal.BookingCode = this.BookingCode
        this.getListBookingRBK02A = res.Data.TeeTime
        this.BookingGroup = res.Data.TeeTime[0].Booking
        res.Data.TeeTime.forEach(x => {
          this.BookingCal.OpenDate.push(this.convertUTC(x.OpenDate, "DD/MM/YYYY HH:mm" ))
          this.BookingCal.TeeTime.push(`${x.TeeName} - ${this.convertUTC(x.OpenDate, "DD/MM/YYYY HH:mm" )} `)
          x.Booking.forEach(y => {
            this.BookingCal.BookingId = y.BookingDetail[0].BookingId
            this.BookingCal.TotalPrice += y.TotalPrice
            this.BookingCal.TotalNumberOfGolfer += y.BookingDetail.length
            y.BookingDetail.forEach(z => {
              this.listBookingDetail.push(z)
            })
          })
        } )
        this.BookingCal.TotalPrice = this.formatPrice(this.BookingCal.TotalPrice)
        this.dataBooking = this.BookingCal
        return this.call_api_RCOM01()
      })
    },
    async call_api_RBK02B(Id) {
      const body = {
        BookingDetail: {
          Id: Id
        }
      }
      await booking.getDetailBooking2B(body).then(response => {
        this.BookingDetail = response.Data.Booking.BookingDetail;
        this.BookingDetail.OpenDate = this.convertUTC(
          this.BookingDetail.OpenDate,
          "DD/MM/YYYY HH:mm"
        );
        this.BookingDetail.TotalPrice = this.formatPrice(this.BookingDetail.TotalPrice)
        this.dataBooking = this.BookingDetail
        this.Booking = response.Data.Booking;
        this.Booking.Url = this.htmlAll.find(x => x.KeyCode == "URL_IMAGE").KeyValue
        this.BookingDetailCourse = this.BookingDetail.BookingDetailCourse
        this.replaceEmail()
      })
    },
    async call_api_RCOM01() {
      this.type = "BOOKED_GROUP"
      const body = {
        KeyGroup: "EMAIL_CONFIRMATION",
      };
      await commonServices.getCommon(body).then(res => {
        this.htmlAll = res.Data
        this.HtmlAllVie = res.Data
        this.BookingCal.Url = this.htmlAll.find(x => x.KeyCode == "URL_IMAGE").KeyValue
        var ob =  res.Data.find(x => x.KeyCode == "BOOKED_GROUP").KeyValue
        for (const key in this.BookingCal) {
          if (Object.hasOwnProperty.call(this.BookingCal, key)) {
            const element = this.BookingCal[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        this.keyValue = ob;
        setTimeout( () => {
          this.show = true;
        this.htmlEmail = this.keyValue
        },1000 )
      })
    },
    exportPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    openSidebarSendEmail() {
      this.$refs.SendEmail.open()
    },
    EmailDetail(BookingDetailId) {
      this.type = 'BOOKED'
      this.call_api_RBK02B(BookingDetailId)
    },
    EmailGroup() {
      this.type = "BOOKED_GROUP"
      this.replaceEmail()
    },
    async getListLanguages() {
      await commonServices.getLanguages()
        .then(res => {
          if (res) {
            this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
          }
        })
    },
   async call_api_RCOM01_getHtmlEng() {
      this.type = "BOOKED_GROUP"
      const body = {
        KeyGroup: "EMAIL_CONFIRMATION",
        LangOfData: "1000001"
      };
      await commonServices.getCommon(body).then(res => { 
        this.HtmlAllEng = res.Data
      })},
    replaceEmail() {
      if(this.type == "BOOKED_GROUP") {
        var ob =  this.htmlAll.find(x => x.KeyCode == "BOOKED_GROUP").KeyValue
        for (const key in this.BookingCal) {
          if (Object.hasOwnProperty.call(this.BookingCal, key)) {
            const element = this.BookingCal[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        this.keyValue = ob;
        setTimeout( () => {
          this.show = true;
        this.htmlEmail = this.keyValue
        },1000 )
        this.dataBooking = this.BookingCal
      }
      if(this.type == "BOOKED") {
        var ob =  this.htmlAll.find(x => x.KeyCode == "BOOKED").KeyValue
        for (const key in this.BookingDetail) {
          if (Object.hasOwnProperty.call(this.BookingDetail, key)) {
            const element = this.BookingDetail[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        for (const key in this.Booking) {
          if (Object.hasOwnProperty.call(this.Booking, key)) {
            const element = this.Booking[key];
            var exp = new RegExp(`{{ ${key} }}`, "g");
            ob = ob.replace(exp, element);
          }
        }
        this.keyValue = ob;
          this.show = true;
          setTimeout(() => {
          const TotalNumberOfHole = document.getElementById("TotalNumberOfHole");
          for ( let i = 0 ; i < this.BookingDetailCourse.length; i++ ){
            const span = document.createElement('span')
            span.innerHTML= `${this.BookingDetailCourse[i].Course.CourseName}-${this.BookingDetailCourse[i].NumberOfHole} `
            TotalNumberOfHole.append(span)
          }
        }, 1000);
        
        setTimeout( () => {
        this.htmlEmail = this.keyValue
        this.htmlEmail = this.htmlEmail.replace('<td id="TotalNumberOfHole" style="width:70%;border:1px solid;"></td>', String(document.getElementById("TotalNumberOfHole").outerHTML))
        },1000 )
      }
    }
  },
};
</script>
<style lang="scss">
.left-body {
  width: 25%;
}
.right-body{
width: 75%;
}
.top-content {
  display: flex;
  justify-content: flex-end;
}
#confirmEmail {
  height: 297mm;
  width: 210mm;
}
.vue-html2pdf .layout-container {
  display: contents !important;
}
</style>